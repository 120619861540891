export const environment = {
 production: false,
  baseUrl: 'https://fouka-webapi.veetech.uk/api',
  baseSocket: 'https://fouka-contapi.veetech.uk',
  mapApiKey: 'AIzaSyAmVLyQOxFx4eA-8pD1_tcp_jT57LxxoSc',
  projectLogo: 'assets/images/logo.png',
  qrConfigArray: [
    {
      name: 'available days',
      type: 'number',
    },
    {
      name: 'one time use',
      type: null,
    },
    {
      name: 'started',
      type: null,
    },
    {
      name: 'ended',
      type: null,
    },
    {
      name: 'start time',
      type: null,
    },
    {
      name: 'increment qr',
      type: null,
    },
    {
      name: '30 seconds',
      type: null,
    },
    {
      name: 'full day access',
      type: null,
    },
    {
      name: 'count',
      type: null,
    },
    {
      name: 'guest invitation',
      type: null,
    },
    {
      name: 'owner existence',
      type: null,
    },
  ],

  qrCodeCard: {
    logo: true,
    inviter: true,
    visitor: true,
    visitorPhone: true,
    visitorEmail: true,
    unit: true,
    peopleCount: true,
    visitDate: true,
  },

  printingCardOrientation: [
    {
      userType: 'Main Owner',
      orientation: 'portrait',
    },
    {
      userType: 'Owner',
      orientation: 'portrait',
    },
    {
      userType: 'Guest',
      orientation: 'landscape',
    },
    {
      userType: 'Other Staff',
      orientation: 'landscape',
    },
  ],
};
